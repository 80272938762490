import {IoCamera, IoClose} from "react-icons/io5";
import React from "react";

export default function ({values, setValues, functions}) {
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: "30px" }}>
            <h2>Immagini</h2>
            <div className="imageContainer">
                {
                    values.loadedImages && values.loadedImages.map((image) => {
                        return (
                            <div key={image.icon} style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: 10
                            }}>
                                <img src={image.icon} loading="lazy" className="image" alt="Scatto del lavoro"/>
                                <IoClose color={"grey"} size={24} style={{cursor: "pointer"}} onClick={() => {
                                    functions.deleteImage(image)
                                }}/>
                            </div>
                        )
                    })
                }
                {
                    values.newImages && values.newImages.map((image, idx) => {
                            return (
                                <div key={URL.createObjectURL(image)} style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    margin: 10
                                }}
                                >
                                    <img src={URL.createObjectURL(image)} loading="lazy" className="image"
                                         alt="Immagine del lavoro da caricare"/>
                                    <IoClose color={"grey"} size={24} style={{cursor: "pointer"}} onClick={() => {
                                        let newImages = [...values.newImages]
                                        // remove current row
                                        newImages.splice(idx, 1)
                                        setValues.setNewImages(newImages)
                                    }}/>
                                </div>
                            )
                        }
                    )
                }
            </div>
            <div>
                <input type="file" id="images" name="images" accept="image/*" multiple
                       style={{display: "none"}}
                       onChange={(e) => {
                           let tempImages = [...values.newImages]
                           for (let i = 0; i < e.target.files.length; i++) {
                               tempImages.push(e.target.files[i])
                           }
                           setValues.setNewImages(tempImages)
                       }
                       }/>
                <button className="button" onClick={() => document.getElementById('images').click()}>
                    <IoCamera size={24}/>
                    &nbsp;
                    Aggiungi
                </button>
            </div>
        </div>
    )
}