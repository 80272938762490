import React, { useEffect } from "react";

import { useState } from "react";
import {
    IoPerson,
    IoClipboard,
    IoPeople,
    IoCheckmark,
    IoResize,
    IoTime,
    IoMoon,
    IoEye,
    IoPencil,
    IoTrash, IoHourglassOutline
} from "react-icons/io5";
import { FaHashtag } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import "./Home.css";

import LoadingSpinner from "../../components/LoadingSpinner";
import LoadingError from "../../components/LoadingError";
import NavBar from "../../components/NavBar";
import DailyHours from "../../components/Home/DailyHours";
import {FaMoneyBillTransfer} from "react-icons/fa6";
import {useUserInfo} from "../../useUserInfo";
import toHtmlInputDate from "../../components/functions/toHtmlInputDate";
import {RiProgress5Line} from "react-icons/ri";


const Home = () => {

    // Set navigatation bar title
    const navigate = useNavigate();

    const isMobile = window.innerWidth < 768;

    const { userInfo } = useUserInfo();

    //Page state
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [errorType, setErrorType] = useState("")
    const [works, setWorks] = useState([]);
    const [date, setDate] = useState(toHtmlInputDate(new Date()));

    const [showDetail, setShowDetail] = useState([]);


    const loadWorks = async () => {

        try {

            const url = process.env.REACT_APP_BACKEND_URL + "/works/" + date
            console.log(url)
            const response = await fetch(url, {
                method: "GET",
                credentials: "include",
            })

            if (response.status === 200) {
                const data = await response.json()
                setWorks(data)
                works.map(() => {
                    setShowDetail([...showDetail, false])
                })
                setIsLoading(false)
            }
        } catch (e) {
            setErrorType("Network error")
            setLoadingError(true)
        }

    }

    useEffect(() => {
        loadWorks().then(() => {})
    }, [date]);

    //Keep the prev date even if the user refreshes the page
    useEffect(() => {
        try {

            if (new Date(JSON.parse(localStorage.getItem("date")).savedAt).getDate() === new Date().getDate()){
                setDate(JSON.parse(localStorage.getItem("date")).value !== "" ? JSON.parse(localStorage.getItem("date")).value : toHtmlInputDate(new Date()))
            }
            else {
                localStorage.setItem("date", JSON.stringify({ savedAt: new Date(), value: toHtmlInputDate(new Date()) }))
                setDate(toHtmlInputDate(new Date()))
            }

        } catch (e) {

            //Handle the case in which the date is not in the local storage
            localStorage.setItem("date", JSON.stringify({savedAt: new Date(), value: toHtmlInputDate(new Date())}))
            setDate(toHtmlInputDate(new Date()))
        }


    },[])


    const deleteReport = (report) => {
        if (window.confirm("Sei sicuro di voler eliminare il rapportino?")) {
            fetch(process.env.REACT_APP_BACKEND_URL + "/works/" + report.id, {
                method: "DELETE",
                credentials: "include",
            })
                .then((res) => {
                    if (res.status === 200) {
                        res.json().then((data) => {
                            loadWorks();
                        });
                    }
                    else if (res.status === 401) {
                        window.alert("Non sei autorizzato ad eliminare questo rapportino; Sono necessari i privilegi di amministratore")
                    }
                })
                .catch((err) => {
                    setLoadingError(true);
                });
        }
    }

    return (
        (loadingError) ? <LoadingError errorDescription={errorType}/> :
        (isLoading) ? <LoadingSpinner /> :
        <div className="home mainContainer">
            <NavBar />

            <div className="datePickerContainer">
                <p>Seleziona la giornata</p>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => {
                        setDate(e.target.value)
                        localStorage.setItem("date", JSON.stringify({savedAt: new Date(), value: e.target.value}))
                    }}
                />
            </div>

            <DailyHours works={works} date={date} />
            <table hidden={isMobile}>
                <thead>
                    <tr>
                        <th><FaHashtag size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> ID</th>
                        <th><IoPerson size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Cliente</th>
                        <th><IoClipboard size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Descrizione</th>
                        <th><IoPeople size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Operatori</th>
                        <th><IoTime size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Ore</th>
                        <th><IoMoon size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Rep</th>
                        <th><RiProgress5Line size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Condizione</th>
                        <th><FaMoneyBillTransfer size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} />Stato</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {works.map((work) => (
                        <tr key={work.id}>

                            <td><p className="table_elements">{new Date(work.labour[0].date).getFullYear() + "-" + work.id}</p></td>

                            <td>{work.customer}</td>
                            <td >{
                                //if description is too long, show only the first 150 characters and then a "..."
                                work.description.length > 150 ? <p>{work.description.substring(0, 150)}...</p> : <p>{work.description}</p>
                            }</td>
                            <td>
                                {work.labour.map((labour) => {
                                        let labourDate = new Date(labour.date)
                                        const offset = labourDate.getTimezoneOffset()
                                        labourDate = new Date(labourDate.getTime() - (offset*60*1000))
                                        if (labourDate.toISOString().split('T')[0] === date) {
                                            return labour.users.map((user) => {
                                                return <p key={user.id} className="table_elements">{user.name} {user.surname}</p>
                                            })
                                        }
                                    })
                                }
                            </td>
                            <td>
                                {work.labour.map((labour) => {

                                    //Tutta sta sbrodolata per rendere compatibili i due valori delle date
                                    let labourDate = new Date(labour.date)
                                    const offset = labourDate.getTimezoneOffset()
                                    labourDate = new Date(labourDate.getTime() - (offset*60*1000))
                                    if (labourDate.toISOString().split('T')[0] === date) {
                                        return labour.users.map((user) => {
                                            return <p key={user.id} className="table_elements">{user.hours}:{user.minutes}{user.minutes < 10 ? 0 : null}</p>
                                        })
                                    }
                                })}
                            </td>

                            <td>
                                <p className="table_elements">{work.oncall ? "Si" : "No"}</p>
                            </td>

                            <td>
                                {work.completed ? <div className="date" style={{ backgroundColor: "#d4f4cd", color: "#133213" }}>Completato</div>
                                    :
                                    <div className="date" style={{ backgroundColor: "#f4d4d4", color: "#331313" }}>In lavorazione</div>}
                            </td>

                            <td>
                                {work.processed ? <div className="date" style={{ backgroundColor: "#d4f4cd", color: "#133213" }}>Contabilizzato</div>
                                    :
                                <div className="date" style={{ backgroundColor: "#f4d4d4", color: "#331313" }}>Da contabilizzare</div>}
                            </td>

                            <td>
                                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
                                <IoEye color="grey" size={24} style={{ marginLeft: 10, cursor: "pointer" }} onClick={() => { navigate("/work/" + work.id) }} />
                                { !work.processed ? <IoPencil color="grey" size={24} style={{ marginLeft: 10, cursor: "pointer" }} onClick={() => { navigate("/edit/" + work.id) }} /> : null }
                                { (!work.processed && userInfo.isAdmin) ? <IoTrash color="grey" size={24} style={{ marginLeft: 10, cursor: "pointer" }} onClick={() => { deleteReport(work) }} /> : null}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table hidden={!isMobile} style={{ paddingInline: 5}}>
                <thead>
                    <tr>
                        <th><FaHashtag size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> ID</th>
                        <th><IoPerson size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /> Cliente</th>
                        <th><RiProgress5Line size={20} style={{ verticalAlign: "bottom", marginRight: "4px" }} /></th>
                        <th />
                    </tr>
                </thead>

                {isLoading && <LoadingSpinner />}
                {loadingError && <LoadingError />}
                {works.map((report, index) => (
                    <tbody key={report.id}>
                    <tr>
                        <td style={{borderBottom: showDetail[index] ? "none" : "1px solid #ddd"}}><p
                            className="table_elements">{new Date(report.labour[0].date).getFullYear() + "\n" + report.id}</p>
                        </td>
                        <td style={{
                            paddingInline: 3,
                            borderBottom: showDetail[index] ? "none" : "1px solid #ddd"
                        }}>{report.customer}
                        </td>
                        <td style={{
                            margin: "10px",
                            borderBottom: showDetail[index] ? "none" : "1px solid #ddd"}}>
                            {report.completed ?
                                <div className="date" style={{
                                    backgroundColor: "#d4f4cd",
                                    color: "#133213",
                                    width: "24px",
                                    height: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}><IoCheckmark size={30}/></div>
                                :
                                <div className="date" style={{
                                    backgroundColor: "#f4d4d4",
                                    color: "#331313",
                                    width: "24px",
                                    height: "24px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <IoHourglassOutline  size={30}/>
                                </div>}
                        </td>
                        <td style={{
                            paddingInline: 0,
                            paddingRight: 5,
                            borderBottom: showDetail[index] ? "none" : "1px solid #ddd"
                        }}>
                            <IoEye color="grey" size={24} style={{marginLeft: 10, cursor: "pointer", margin: 5}}
                                   onClick={() => {
                                       navigate("/work/" + report.id)
                                   }}/>
                            <IoResize color={"grey"} size={24} style={{marginLeft: 10, cursor: "pointer", margin: 5}}
                                      onClick={() => {
                                          let newShowDetail = [...showDetail]
                                          newShowDetail[index] = !newShowDetail[index]
                                          setShowDetail(newShowDetail)
                                      }}/>
                            {!report.processed ?
                                <IoPencil color="grey" size={24} style={{marginLeft: 10, cursor: "pointer", margin: 5}}
                                        onClick={() => {
                                            navigate("/edit/" + report.id)
                                        }}/> : null}
                            {(!report.processed && userInfo.isAdmin) ? <IoTrash color="grey" size={24} style={{
                                marginLeft: 10,
                                cursor: "pointer",
                                margin: 5
                            }} onClick={() => {
                                deleteReport(report)
                            }}/> : null}
                        </td>
                    </tr>
                    <tr hidden={!showDetail[index]}>
                        <td colSpan={4} style={{
                            borderBottom: "none"
                        }}>
                            <p style={{padding: 5, margin: 0}}>{report.description}</p>
                        </td>
                    </tr>
                    <tr hidden={!showDetail[index]}>
                        <td colSpan={2}>
                            {report.labour.map((labour) => {
                                let labourDate = new Date(labour.date)
                                const offset = labourDate.getTimezoneOffset()
                                labourDate = new Date(labourDate.getTime() - (offset * 60 * 1000))
                                if (labourDate.toISOString().split('T')[0] === date) {
                                    return labour.users.map((user) => {
                                        return <p key={user.id}
                                                  className="table_elements">{user.name} {user.surname}</p>
                                    })
                                }
                            })
                            }
                        </td>

                        <td colSpan={2}>
                            {report.labour.map((labour) => {

                                //Tutta sta sbrodolata per rendere compatibili i due valori delle date
                                let labourDate = new Date(labour.date)
                                const offset = labourDate.getTimezoneOffset()
                                labourDate = new Date(labourDate.getTime() - (offset * 60 * 1000))
                                if (labourDate.toISOString().split('T')[0] === date) {
                                    return labour.users.map((user) => {
                                        return <p key={user.id}
                                                  className="table_elements">{user.hours}:{user.minutes}{user.minutes < 10 ? 0 : null}</p>
                                    })
                                }
                            })}
                        </td>
                    </tr>
                    </tbody>
                ))}
            </table>
        </div>
    );
}

export default Home;