import {useEffect} from "react";
import { useNavigate } from "react-router-dom";

const Splash = () => {

    const navigate = useNavigate();

    useEffect( () => {
        //Crear the all data in local storage
        localStorage.clear();

        //Redirect to home
        //wait for 1 second
        setTimeout(() => {
            navigate("/home");
        }, 1000);

    }, []);

    return (
        <div className={"mainContainer"}>
            <h1>Pulizia in corso...</h1>
        </div>
    );
}

export default Splash;