import NavBar from "../../components/NavBar";
import { IoLogOutOutline, IoRefresh } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import preval from 'preval.macro'
import LoadingError from "../../components/LoadingError";
import {useEffect, useState} from "react";

import "./Settings.css";
import {useUserInfo} from "../../useUserInfo";

import VehicleStatus from "../../components/Settings/VehicleStatus";
import {BiSolidCarMechanic} from "react-icons/bi";
import LoadingSpinner from "../../components/LoadingSpinner";
import {LiaBroomSolid} from "react-icons/lia";

const Settings = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [errorDescription, setErrorDescription] = useState("");

    const [vehicles, setVehicles] = useState([]);

    const { setUserInfo } = useUserInfo();

    const setVehiclesStatus = async () => {
        try {

            setVehicles([])
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/vehicles", {
                method: "GET",
                credentials: "include",
            })

            const data = await response.json()

            for (const vehicle of data.vehicles) {
                let status = "ok"

                //30 g
                const days = 30 * 24 * 60 * 60 * 1000
                if (new Date(vehicle.stamp) - new Date() < days || new Date(vehicle.insurance) - new Date() < days || new Date(vehicle.revision) - new Date() < days) {
                    status = "warning"
                }

                if (new Date(vehicle.stamp) - new Date() < 0 || new Date(vehicle.insurance) - new Date() < 0 || new Date(vehicle.revision) - new Date() < 0) {
                    status = "expired"
                }

                setVehicles(vehicles => [...vehicles, {...vehicle, status: status}])
            }

            setIsLoading(false)

        } catch (e) {
            setLoadingError(true)
            console.log(e)
        }

    }


    const logout = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/logout", {
            method: "GET",
            credentials: "include",
        }).then((res) => {
            if (res.status === 200) {
                setUserInfo({isAuth: false});
                navigate("/login")
            }
            else {
                setLoadingError(true);
                res.json().then((data) => {
                    setErrorDescription(data.message);
                })
            }
        })
            .catch(() => {
                setErrorDescription("Network error");
                setLoadingError(true);
            })
    }

    useEffect(() => {
        setVehiclesStatus().then(() => {console.log(vehicles)});
    }, []);

    //convert to ISO string to italy build time
    const buildDateTime = preval`module.exports = new Date().toLocaleString("it-IT", { timeZone: "Europe/Rome" });`;
    const actualYear = new Date().getFullYear();

    return (
        loadingError ? <LoadingError errorDescription={errorDescription} /> :
        isLoading ? <LoadingSpinner /> :
        <div className="mainContainer settings" style={{ width: "100%" }}>
            <NavBar />
            <h1>Settings</h1>

            <div className="vehiclesContainer">
                <VehicleStatus percentuage={Math.floor((vehicles.filter(veh => (veh.status !== "expired" )).length/vehicles.length)*100)} />
                <div className="vehiclesInfo">
                    <h2>Veicoli Operativi</h2>
                    {(vehicles.filter(veh => veh.status === "ok").length === vehicles.length) ?
                        <p>Nessuna scadenza infranta</p> :
                        (vehicles.filter(veh => veh.status === "expired").length > 1) ?
                            <p>Alcuni veicoli non idonei all'utilizzo</p> :
                            <p>Scadenze imminenti</p>
                    }
                    <button className="button" onClick={() => navigate("/settings/vehicles")}>
                        <BiSolidCarMechanic size={24}/>
                        &nbsp;
                        Gestisci
                    </button>
                </div>
            </div>

            <div className="listContainer">
                <button className="button" onClick={() => window.location.reload()}>
                    <IoRefresh size={24}/>
                    &nbsp;
                    Aggiorna
                </button>
                <button className="button" onClick={() => navigate("/purge")}>
                    <LiaBroomSolid size={24}/>
                    &nbsp;
                    Pulisci
                </button>
                <button className="delete button" onClick={logout}>
                    <IoLogOutOutline size={24}/>
                    &nbsp;
                    Logout
                </button>
            </div>


            <div className="footer">
                <p>© {actualYear} Badia Silvano</p>
                {(window.innerWidth > 767) && "\xa0\xa0|\xa0\xa0"}
                <p>Actual build: {buildDateTime}</p>
            </div>
        </div>
    )
}

export default Settings