import "@fontsource/jetbrains-mono";
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Splash from './Pages/Splash';
import RequireAuth from './RequireAuth';
import UserInfoProvider from './useUserInfo';
import Add from './Pages/Add/Add';
import Work from './Pages/Work/Work';
import Settings from './Pages/Settings/Settings';
import SetName from './Pages/SetName';
import Edit from './Pages/Edit/Edit';
import Search from './Pages/Search/Search';
import OnCallCalendar from "./Pages/Calendar/Calendar";
import RefuelingAdd from "./Pages/Refueling/RefuelingAdd";
import Refueling from "./Pages/Refueling/Refueling";
import Event from "./Pages/Calendar/event/Event";
import EventAdd from "./Pages/Calendar/event/add/EventAdd";
import VehiclesManagement from "./Pages/VehiclesManagement/VehiclesManagement";
import Purge from "./Pages/Purge/Purge";

function App() {

  return (
    <div className="App">
      <UserInfoProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/setname" element={<SetName />} />
            <Route path="/home" element={<Home />} />
            <Route path="/add" element={<Add />} />
            <Route path="/edit/:id" element={<Edit />} />
            <Route path="/work/:id" element={<Work />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/vehicles" element={<VehiclesManagement />} />
            <Route path="/search" element={<Search />} />
            <Route path="/calendar" element={<OnCallCalendar />} />
            <Route path="/calendar/event/:id" element={<Event />} />
            <Route path="/calendar/add" element={<EventAdd />} />
            <Route path="/refueling" element={<Refueling />} />
            <Route path="/refueling/add" element={<RefuelingAdd />} />
          </Route>
          <Route path="/purge" element={<Purge />} />
          <Route path="*" element={<Splash />} />
        </Routes>
      </UserInfoProvider>
    </div>
  );
}

export default App;
